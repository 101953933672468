<template>
  <v-container fluid>
    <!-- Section Path File/Modul -->
    <v-breadcrumbs
      :items="pathModul"
      divider="-"
      normal
      class="pathModul"
    ></v-breadcrumbs>

    <!-- Section Filtering Input/Select/Button -->
    <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">
      <v-row align="center">
        <!-- Column Select Station -->

        <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'" class="col-input-filter py-1">
          <v-select
            v-on:change="selectValueStation"
            :items="itemStation"
            label="Station"
            prepend-inner-icon="mdi-store-marker"
            solo
            dense
          ></v-select>
        </v-col>

        <!-- Column Select Calendar From-->
        <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'" class="col-input-filter py-1">
          <v-menu
            v-model="modalCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFrom"
                prefix="From :"
                prepend-inner-icon="mdi-calendar-start"
                readonly
                v-bind="attrs"
                v-on="on"
                solo
                dense
              >
              </v-text-field>
            </template>
            <v-date-picker v-model="dateFrom" @input="modalCalendar = false">
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Select Calendar To-->
        <v-col :cols="$vuetify.breakpoint.lgAndUp? '' : '12'" class="col-input-filter py-1">
          <v-menu
            v-model="modalCalendar2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateTo"
                prefix="To :"
                prepend-inner-icon="mdi-calendar-end"
                readonly
                v-bind="attrs"
                v-on="on"
                solo
                dense
              >
              </v-text-field>
            </template>
            <v-date-picker v-model="dateTo" @input="modalCalendar2 = false">
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- <v-col>
                    <v-text-field @input="test">

                    </v-text-field>
                </v-col> -->

        <!-- Column Generate Button -->
        <v-col cols="12" sm="6" md="4">
          <v-btn
            id="generateReport"
            class="mb-5 white--text btn-generate button"
            solo dense normal
            @click="generateTable"
            :disabled="valStation==null"
          >
            GENERATE REPORT
          </v-btn>
        </v-col>
      </v-row>

      <v-card :style="$vuetify.breakpoint.lgAndUp? 'max-height: calc(100vh - 230px);overflow-y:auto;' : ''">

        <v-tabs v-model="tabs" show-arrows="mobile">
          <v-tab v-for="item in tabsTypeQAQC2ndLevel" :key="item.tab" >
            <span v-html="item.tab"></span>
            <!-- {{ item.tab.split("sec")[0] + " " }} <sup>nd</sup>
            <span class="ml-1 white--text">{{ item.tab.split("sec")[1] }}</span> -->
          </v-tab>
        </v-tabs>

        <!-- Mini Title (Table Title) -->
        <v-subheader class="subTitle black--text font-weight-bold">
          QAQC 2<sup>nd</sup> Level Summary For Station {{ stationID }} - {{ locationStation }} From {{convertDateFormat(dateFrom)}} To {{convertDateFormat(dateTo)}}
        </v-subheader>

        <v-tabs-items v-model="tabs" touchless class="mt-4">
          <!-- Content Tab QAQC 2nd Level Report -->
          <v-tab-item class="pa-4">

            <!-- Button Export Table Tab Report -->
            <div v-show='showExportBtn' v-if="this.$store.getters.user.idUserAccess !== 3" class="section-btn-export">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  color="excel"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportExcel()"
                  class="btn-export-table btn-excel"
                  >
                    <v-icon
                    color="white"
                    center
                    dark
                    >
                        mdi-microsoft-excel
                    </v-icon>
                  </v-btn>
                </template>
                <span>Export to Excel</span>
              </v-tooltip>
            </div>

            <!-- Table Report Tab Report -->
            <v-data-table
              :headers="headerReport"
              :items="bodyReport"
              :header-props="{ sortIcon: null }"
              class="elevation-1 tableQ2Report headerDtSarawak headerDesktop"
              :items-per-page="itemPerPageReport"
              no-select-on-click
              fixed-header
              :loading="this.loadingTable"
              :loading-text="this.loadingText"
              :custom-sort="customSort"
              :mobile-breakpoint="0" 
            >
              <template v-slot:[`item.flag`]="{ item }">
                <font color="red"
                  ><b>{{ item.flag }}</b></font
                >
              </template>
            </v-data-table>
          </v-tab-item>

          <!-- Content Tab QAQC 2nd Level Pending -->
          <v-tab-item class="px-4">

            <!-- Button Export Table Tab Report -->
            <!-- <div class="section-btn-export">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  color="excel"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportReport('excel', 'report')"
                  class="btn-export-table btn-excel"
                  >
                    <v-icon
                    color="white"
                    center
                    dark
                    >
                        mdi-microsoft-excel
                    </v-icon>
                  </v-btn>
                </template>
                <span>Export to Excel</span>
              </v-tooltip>
  
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  color="csv"
                  class="mt-3 ml-3"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportCSV('headerPending','bodyPending')"
                  >
                    <v-icon
                    color="white"
                    center
                    dark
                    >
                        mdi-file-delimited
                    </v-icon>
                  </v-btn>
                </template>
                <span>Export to CSV</span>
              </v-tooltip>
            </div> -->

            <!-- Table Report Tab Pending -->
            <v-data-table
              :headers="headerPending"
              :items="bodyPending"
              :header-props="{ sortIcon: null }"
              class="elevation-1 tableQ2Pending headerDtSarawak headerDesktop"
              :items-per-page="10"
              no-select-on-click
              fixed-header
              :loading="this.loadingTable"
              :loading-text="this.loadingText"
              :custom-sort="customSort"
              :mobile-breakpoint="0"
            >
              <template v-slot:[`item.flag`]="{ item }">
                <font color="red"
                  ><b>{{ item.flag }}</b></font
                >
              </template>

              <!-- Action Status -->
              <template v-slot:[`item.status`]="{ item }">
                <v-container>
                  <v-col cols="12">
                    <v-select
                      v-model="item.status"
                      :items="actionStatus"
                      label="Accept/Reject"
                      outlined
                      dense
                      @change="selectStatus(item)"
                    ></v-select>
                  </v-col>
                </v-container>
              </template>

              <!-- Action Remarks -->
              <template v-slot:[`item.remarks`]="{ item }">
                <v-container>
                  <v-col cols="12">
                    <v-textarea
                      v-model="item.remarks"
                      label="Remarks"
                      outlined
                      @input="typingRemarks(item)"
                    ></v-textarea>
                  </v-col>
                </v-container>
              </template>

              <!-- Checking Checkbox -->
              <template v-slot:[`item.checkbox`]="{ item }">
                <v-container>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="item.checkbox"
                      :value="item.checkbox"
                      @change="SelectRowTable(item, item.checkbox)"
                    ></v-checkbox>
                  </v-col>
                </v-container>
              </template>
            </v-data-table>

            <div class="center-el" style="text-align:center;">
              <v-btn
                class="btn-after-table primary"
                :disabled="dataSubmit.length == 0 ? true : false"
                @click="submitPending"
              >
                SUBMIT
              </v-btn>

              <v-btn
                class="btn-after-table primary"
                @click="uncheckAll"
              >
                CANCEL
              </v-btn>
            </div>
          </v-tab-item>

          <!-- Content Tab QAQC 2nd Level History -->
          <v-tab-item class="px-4">
            <!-- Button Export Table Tab Report -->
            <div v-show='showExportBtn' class="section-btn-export">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                  color="excel"
                  elevation="2"
                  v-bind="attrs"
                  v-on="on"
                  @click="exportReportH()"
                  class="btn-export-table btn-excel"
                  >
                    <v-icon
                    color="white"
                    center
                    dark
                    >
                        mdi-microsoft-excel
                    </v-icon>
                  </v-btn>
                </template>
                <span>Export to Excel</span>
              </v-tooltip>
            </div>

            <!-- Table Report Tab History -->
            <v-data-table
              :headers="headerHistory"
              :items="bodyHistory"
              :header-props="{ sortIcon: null }"
              class="elevation-1 tableQ2History headerDtSarawak headerDesktop"
              :items-per-page="itemPerPageHistory"
              no-select-on-click
              fixed-header
              :loading="this.loadingTable"
              :loading-text="this.loadingText"
              :custom-sort="customSort"
              :mobile-breakpoint="0"
            >
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>

        <!-- Footer Legend -->
        <template>
          <v-footer padless style="margin: 20px 0px;">
            <v-col class="text-center footer-report" cols="12">
              <!-- <span class="one-text-footer mx-5">
                          <span class="red--text">C</span>
                          <span class="blue--text">=CALIBRATION</span>
                      </span>
                      <span class="one-text-footer mx-5">
                          <span class="red--text">M</span>
                          <span class="blue--text">=MAINTENANCE</span>
                      </span>
                      <span class="one-text-footer mx-5 dontBreak">
                          <span class="red--text">DOR</span>
                          <span class="blue--text">=DATA OUT OF SENSOR RANGE</span>
                      </span><br> -->
              <span class="one-text-footer mx-5 dontBreak">
                <span class="red--text">OUTL</span>
                <span class="blue--text">=OUTLIER</span>
              </span>
              <span class="one-text-footer mx-5 dontBreak">
                  <span class="red--text">CONST</span>
                  <span class="blue--text">=CONSTANT READING</span>
              </span>
            </v-col>
          </v-footer>
        </template>

      </v-card>

    </v-container>

    <!-- Popups/Dialogs Succeed Section Tab Pending -->
    <div class="text-center">
      <v-dialog v-model="dialogSucceedPending" width="500">
        <v-card>
          <v-card-title class="text-h5" style="background:#106f79;color:white;">
            Success!
            <v-icon style="color:white;margin-left:15px;"
              >mdi-text-box-check-outline</v-icon
            >
          </v-card-title>

          <v-card-text style="padding:20px 24px;font-weight:bold;">
            All data selected submitted successfully.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogSucceedPending = false">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- Popups/Dialogs Error Section Tab Pending -->
    <div class="text-center">
      <v-dialog v-model="dialogErrorPending" width="500">
        <v-card>
          <v-card-title class="text-h5 red lighten-2" style="color:white;">
            Warning!
            <v-icon style="color:white;margin-left:15px;">mdi-alert</v-icon>
          </v-card-title>

          <v-card-text style="padding:20px 24px;font-weight:bold;">
            {{ textError }}
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              style="color:red !important;"
              color="primary"
              text
              @click="dialogErrorPending = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import Papa from "papaparse";

export default {
  data: () => ({
    // General
    itemPerPageReport: 10,
    itemPerPageHistory: 10,

    loadingTable: false,
    loadingText: "",
    expIcon: null,
    pathModul: [
      {
        text: "Home",
        disabled: false,
        href: "/",
        // href: '/Mapvsadiewer',
      },
      {
        text: "QAQC 2nd Level",
        disabled: true,
        href: "/Operational/QAQCSecLevel",
      },
    ],
    textError: "",

    // Filtering Form
    itemStation: [],
    dateFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modalCalendar: false,
    menu2: false,
    modalCalendar2: false,
    showExportBtn: false,

    // Variable/Object for value filtering
    valStation: null,
    valDate: null,

    // Title - Subtitle After Generate Data
    stationID: null,
    locationStation: null,
    generalUser: false,

    // 3 Tabs Generated
    tabs: null,
    tabsTypeQAQC2ndLevel: [
      { tab: "QAQC 2<sup>nd</sup> Level Report" },
      { tab: "QAQC 2<sup>nd</sup> Level Pending" },
      { tab: "QAQC 2<sup>nd</sup> Level History" },
    ],

    // Container Tab Report
    headerReport: [
      {
        text: "STATION ID",
        value: "stationid",
        sortable: false,
        divider: true,
        align: "center",
      },
      {
        text: "DATETIME",
        value: "datetime",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "FLAG SUMMARY",
        value: "flag",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "PARAMETER",
        value: "parameter",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "VALUE",
        value: "value",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "STATUS",
        value: "status",
        sortable: true,
        divider: true,
        align: "center",
      },
    ],
    bodyReport: [],

    // Container Tab Pending
    headerPending: [
      {
        text: "STATION ID",
        value: "stationid",
        sortable: false,
        divider: true,
        align: "center",
      },
      {
        text: "DATETIME",
        value: "datetime",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "PARAMETER",
        value: "parameter",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "VALUE",
        value: "value",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "FLAGGED",
        value: "flag",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "ACTION",
        value: "status",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "ACTION BY REMARK",
        value: "remarks",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "SUBMISSIONS",
        value: "checkbox",
        sortable: true,
        divider: true,
        align: "center",
      },
    ],
    bodyPending: [],
    actionStatus: ["ACCEPT", "REJECT"],
    actionStatusSelected: "",
    actionRemarksTyping: "",
    me: "",
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 19),
    dataSubmit: [],
    disableCheck: true,
    dialogSucceedPending: false,
    dialogErrorPending: false,

    // Container Tab History
    headerHistory: [
      {
        text: "STATION ID",
        value: "stationid",
        sortable: false,
        divider: true,
        align: "center",
      },
      {
        text: "DATETIME",
        value: "datetime",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "PARAMETER",
        value: "parameter",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "VALUE",
        value: "value",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "STATUS",
        value: "status",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "RESOLVE BY",
        value: "resolveby",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "RESOLVE BY REMARK",
        value: "remarks",
        sortable: true,
        divider: true,
        align: "center",
      },
      {
        text: "RESOLVE DATETIME",
        value: "resolvedt",
        sortable: true,
        divider: true,
        align: "center",
      },
    ],
    bodyHistory: [],
  }),

  methods: {

     convert12Hours(time) {
            var H = +time.substr(0, 2);
            var h = H % 12 || 12;
            var ampm = (H < 12 || H === 24) ? "AM" : "PM";
            time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
            return time;
    },

    convertDateFormat(date){
        var dd = date.split("-")[2];
        var mm = date.split("-")[1];
        var yyyy = date.split("-")[0];
        date = dd + "/" + mm + "/" + yyyy;
        return date;
   
    },

    convertUcase(text){

    // console.log(text)

    if (text != null){

      let t_ucase = text.toUpperCase();

      return t_ucase;

      }
    },

    converttoDBFormat(date){

      let tar = date;
      let dt;

      if (tar.includes(" AM")){
          dt = tar.replace(" AM", "_AM")
      } else {
          dt = tar.replace(" PM", "_PM")
      }

      dt = dt.split(" ")
      let tarikh = dt[0];
      let masa = dt[1];



      if (masa.includes("_AM")){
          if (masa.split(":")[0] < 10) {
              masa = "0"+masa.replace("_AM","")
          } else if (masa.split(":")[0] == 12) {
              masa = masa.replace("_AM","").replace("12","00")
          }
          else {
                  masa = masa.replace("_AM","")
          }
      } else {
          masa = masa.replace("_PM","")
          if (masa.split(":")[0] != "12") {
              masa = (parseInt(masa.split(":")[0]) + 12) + ":" + masa.split(":")[1] + ":" + masa.split(":")[2]
          }
      }

      // console.log("masa",masa)

      tarikh = tarikh.split("/")[2] + "-" + tarikh.split("/")[1] + "-" + tarikh.split("/")[0]

      date = tarikh + "T" + masa
      return date;

      // console.log(date)
    },

    selectStatus(data) {
      for (let i in this.dataSubmit) {
        if (
          data.stationid === this.dataSubmit[i].stationid &&
          data.datetime === this.dataSubmit[i].datetime &&
          data.parameter === this.dataSubmit[i].parameter
        ) {
          this.dataSubmit[i].status = data.status;
        }
      }
    },

    typingRemarks(data) {
      for (let i in this.dataSubmit) {
        if (
          data.stationid === this.dataSubmit[i].stationid &&
          data.datetime === this.dataSubmit[i].datetime &&
          data.parameter === this.dataSubmit[i].parameter
        ) {
          this.dataSubmit[i].remarks = data.remarks;
          this.dataSubmit[i].parameter = data.param;
          // this.dataSubmit[i].datetime = this.converttoDBFormat(data.datetime);
        }
      }
    },

    SelectRowTable(data, check) {

      console.log("data",data)
      
      if (check === true) {
        this.dataSubmit.push(data);
      } else {
        for (let i in this.dataSubmit) {
          
          if (
            data.stationid == this.dataSubmit[i].stationid &&
            data.datetime == this.dataSubmit[i].datetime &&
            data.parameter == this.dataSubmit[i].parameter
          ) {
            this.dataSubmit.splice(i, 1);
          }
        }
      }

      console.log("sini",this.dataSubmit);

      // const myJSON = JSON.stringify(this.dataSubmit);
    },

    uncheckAll() {
      for (let i in this.dataSubmit) {
        this.dataSubmit[i]["checkbox"] = false;
      }
      this.dataSubmit = [];
    },

    // Section Filter Form
    loadListStation() {
      axios
        .get(this.globalUrl+"bakaj/stations", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.itemStation = [];

          for (let i = 0; i < response.data.length; i++) {
            this.itemStation.push(
              response.data[i].stationId + " - " + response.data[i].location
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectValueStation: function(e) {
      // console.log(e);
      this.valStation = e;
    },

    // Tab Report
    generateTable() {
      // Keluarkan Details SubText
      this.stationID = this.valStation.split(" - ")[0];
      this.locationStation = this.valStation.split(" - ")[1];

      this.expIcon = "1";
      this.showExportBtn = true;
      this.$store.getters.user.role == 3 ? this.generalUser == true : this.generalUser == false; 


      // Unhide checkbox on header
      document.querySelector(".subTitle").style.display = "block";

      // Clear First all Array Thead and Tbody
      this.bodyReport = [];
      this.bodyPending = [];
      this.bodyHistory = [];

      // Clear Data Selected Pending
      this.dataSubmit = [];

      this.getDataQAQC2ndLevel(this.stationID, this.dateFrom, this.dateTo);
    },

    getDataQAQC2ndLevel(station, startDt, endDt) {
      this.loadingTable = true;
      this.loadingText = "Data Loading... Please wait";

      this.dataSubmit = [];

      let start = startDt + "T00:00:00";
      let end = endDt + "T23:59:59";

      axios
        .get(
          this.globalUrl+"qaqc2/all?stationid=" +
            station +
            "&startdt=" +
            start +
            "&enddt=" +
            end,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          let data = response.data;

          const myJSON = JSON.stringify(data);
          console.log(data);

          // let dataReport = [];
          let dataPending = [];
          let dataHistory = [];
          for (let i in data) {

            if(data[i].param === "TSD" || data[i].param === "TURBIDITY"){
              data[i]["value"] = data[i].value.toFixed(1);
            }
            else if(data[i].param.includes("SI_") == true){
              data[i]["value"] = data[i].value.toFixed(0);
            }
            else{
              data[i]["value"] = data[i].value.toFixed(2);
            }
            
            if (data[i].status == "PENDING") {
              data[i]["checkbox"] = false;
              dataPending.push(data[i]);
            } else if (data[i].status != "PENDING") {
              dataHistory.push(data[i]);
            }
            data[i]["datetime"] = this.convertDateFormat(data[i]["datetime"].split("T")[0]) + " " + this.convert12Hours(data[i]["datetime"].split("T")[1]);
          }

          

          this.bodyReport = data;
          this.bodyPending = dataPending;
          this.bodyHistory = dataHistory;

          this.loadingTable = false;
          this.loadingText = "";
        })
        .catch((error) => {
          console.log(error);
          this.loadingTable = false;
          this.loadingText = "";
          this.textError = "Something went wrong. Please try again.";
          this.dialogErrorPending = true;
        });
    },

    exportExcel(){

      this.itemPerPageReport = -1;

      // Define your style class template.

      let headerName = "<tr = 'border:none !important' ><td colspan ='6' style = 'text-align: center; border:none !important; font-weight: bold'>Data For Station " + this.valStation + " (" + this.stationID+ ")</td></tr>"
      var style = "<style> th, td { border: 10px solid;}</style>";

      var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' + style + '</head><body><table>' + headerName + '{table}</table></body></html>'
      , base64 = function (s) {
          return window.btoa(unescape(encodeURIComponent(s)))
      }
      , format = function (s, c) {
          return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; })
      }

      setTimeout(() => {

        let table = document.querySelectorAll('.v-data-table__wrapper ')
        // console.log("hehe", table[0].innerHTML, table, document.querySelectorAll('.v-data-table__wrapper table'))

        let tablei = table[0].innerHTML
            // if (tablei.includes("℃")){
            tablei = tablei.replaceAll("°C","&#176;C").replaceAll("µ","&#181;")
            // }

        let ctx = { worksheet: "QAQC 2ND LEVEL" || 'Worksheet', table: tablei }
        // window.location.href = uri + base64(format(template, ctx))

        let a = document.createElement('a');
        a.href = uri + base64(format(template, ctx))
        a.download = 'QAQC 2ND LEVEL.xls';
        //triggering the function
        a.click();

        this.itemPerPageReport = 10;

      }, 1000)

    },

    exportReportH(){

      this.itemPerPageHistory = -1

      // Define your style class template.

      let headerName = "<tr = 'border:none !important' ><td colspan ='8' style = 'text-align: center; border:none !important; font-weight: bold'>Data For Station " + this.valStation + " (" + this.stationID+ ")</td></tr>"
      var style = "<style> th, td { border: 1px solid;}</style>";

      var uri = 'data:application/vnd.ms-excel;base64,'
      , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->' + style + '</head><body><table>' + headerName + '{table}</table></body></html>'
      , base64 = function (s) {
          return window.btoa(unescape(encodeURIComponent(s)))
      }
      , format = function (s, c) {
          return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; })
      }

      setTimeout(() => {

        let table = document.querySelectorAll('.v-data-table__wrapper')
        // let table = document.querySelectorAll('.v-data-table__wrapper')
        console.log(table)

        let tablei = table[1].innerHTML
        // if (tablei.includes("℃")){
          tablei = tablei.replaceAll("°C","&#176;C").replaceAll("µ","&#181;")
        // }

        let ctx = { worksheet: "QAQC 2ND LEVEL" || 'Worksheet', table: tablei }
        // window.location.href = uri + base64(format(template, ctx))

        let a = document.createElement('a');
        a.href = uri + base64(format(template, ctx))
        a.download = 'QAQC 2ND LEVEL.xls';
        //triggering the function
        a.click();

        this.itemPerPageHistory = 10;

      }, 1000)

    },

    
    exportCSV(th,td){

      console.log(th,td)

      let headerExportCsv = [];

      if (th == 'headerReport'){

        for(let i in this.headerReport){
          headerExportCsv.push(this.headerReport[i]['value']);
        }

        let keys = headerExportCsv,
        resultAfterFilter = this.bodyReport.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));
      
        let blob = new Blob([Papa.unparse(resultAfterFilter)], { type: 'text/csv;charset=utf-8;' });
        let link = document.createElement("a");
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", 'QAQC 2nd Level - Report.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      else {
        for(let i in this.headerHistory){
          headerExportCsv.push(this.headerHistory[i]['value']);
        }

        let keys = headerExportCsv,
        resultAfterFilter = this.bodyHistory.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));
      
        let blob = new Blob([Papa.unparse(resultAfterFilter)], { type: 'text/csv;charset=utf-8;' });
      
      
        let link = document.createElement("a");

        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", 'QAQC 2nd Level - History.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },

    submitPending() {
      

      let adaErrorTak = "";
      for (let i in this.dataSubmit) {
        // this.dataSubmit[i]["datetime"] = this.convertToDbDt(this.dataSubmit[i]["datetime"].split(", ")[0]) + "T" + this.convert24Hours(this.dataSubmit[i]["datetime"].split(", ")[1]);
        // this.dataSubmit[i]["resolvedt"] = this.convertToDbDt(this.dataSubmit[i]["resolvedt"].split(", ")[0]) + "T" + this.convert24Hours(this.dataSubmit[i]["resolvedt"].split(", ")[1]);

        this.dataSubmit[i].datetime = this.converttoDBFormat(this.dataSubmit[i].datetime);

        if (this.dataSubmit[i].status == "PENDING") {
          adaErrorTak = "adaa";
        } else if (this.dataSubmit[i].remarks == null) {
          adaErrorTak = "adaa";
        } else if (this.dataSubmit[i].remarks == "") {
          adaErrorTak = "adaa";
        }
      }

      console.log("submit",this.dataSubmit);

      if (adaErrorTak == "adaa") {
        this.textError = "Please make sure all form/action is filled";
        this.dialogErrorPending = true;
      } 
      
      else {
        const myJSON = JSON.stringify(this.dataSubmit);
        axios
          .put(
            this.globalUrl+"qaqc2/update",
            this.dataSubmit,
            {
              headers: {
                Authorization: "Bearer " + this.$store.getters.user.token,
              },
            }
          )
          .then((response) => {
            this.getDataQAQC2ndLevel(
            this.stationID,
            this.dateFrom,
            this.dateTo
            );
            this.dialogSucceedPending = true;
          })
          .catch((error) => {
            console.log(error);
            this.dialogSucceedPending = false;
            this.textError = "Something went wrong. Please try again.";
            this.dialogErrorPending = true;
          });
      }
    },

    customSort: function(items, index, isDesc) {

      items.sort((a, b) => {
        if (index[0] == "datetime" || index[0] == "resolvedt") {
          //   console.log(new Date(this.standardDtFormatNuzul(b[index])));
          if (!isDesc[0]) {
            return (
              new Date(this.standardDtFormatNuzul(b[index])) -
              new Date(this.standardDtFormatNuzul(a[index]))
            );
          } else {
            return (
              new Date(this.standardDtFormatNuzul(a[index])) -
              new Date(this.standardDtFormatNuzul(b[index]))
            );
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
    
  },

  mounted() {
    this.loadListStation();
    console.log("test",this.converttoDBFormat("01/01/2023 12:30:00 PM"))
    // console.log(this.$store.getters.user.email);
  },
};
</script>

<style lang="scss">
@import "~scss/main";

// Nuzul
// .v-slide-group__prev > i{
//   color: white !important;
// }

// Korang

// GENERAL
.tableQ2Report,
.tableQ2Pending,
.tableQ2History {
  margin: 20px 0px;
}

/* Section Path File/Modul */
.pathModul {
  padding-bottom: 0px;
}

.pathModul > li:nth-child(3) > a,
.pathModul > li:nth-child(3) > div {
  color: black !important;
}

// Section Subtitle Filtering Table
.subTitle {
  text-align: center !important;
  background: white;
  // color: #1976D2 !important;
  display: none;
  padding: 15px;
}

// Section Container Tab Report

// Section Container Tab Pending
.btn-after-table {
  margin: 0px 10px;
}

// Section Container Tab History

// Section Excel
.btn-export-table {
  margin-top: 12px;
  // margin-bottom: 12px;
}

.section-btn-export {
  text-align: right;
}

// Section Footer
.text-center.footer-report {
  background: white;
}
</style>